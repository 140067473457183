<div class="modal-header">
  <h1 class="modal-title">Contact Us</h1>
  <button (click)="closeModal()" aria-label="Close" class="btn-close" type="button"></button>
</div>

<div class="modal-body">
  <div
    class="alert alert-dismissible"
    [ngClass]="{ 'alert-success': isSuccessMsg, 'alert-danger': !isSuccessMsg }"
    role="alert"
    *ngIf="msg"
  >
    <button type="button" class="btn-close" aria-label="Close" (click)="msg = undefined"></button>
    <div class="alert-message">{{ msg }}</div>
  </div>

  <form style="min-height: 500px" [formGroup]="form">
    <formly-form [hidden]="isCaptchaVisible" [form]="form" [fields]="formFields"></formly-form>
    <div *ngIf="isCaptchaVisible" #captchaContainer data-cy="captcha"></div>
    <ng-container *ngIf="!isCaptchaVisible">
      <p class="text-center mt-3 mb-2" data-cy="supportMessage">
        For pharmacy technical support, please text your NCPDP or call 833.613.2333
      </p>
      <div class="text-center">
        <chitin-button
          label="Submit"
          (clicked)="submit()"
          [disabled]="form.invalid"
          [isLoading]="isSaving"
          data-cy="submitBtn"
        ></chitin-button>
      </div>
    </ng-container>
  </form>
</div>
