import { AbstractControl } from '@angular/forms';
import { ConfigOption, FormlyFieldConfig } from '@ngx-formly/core';
import { FieldValidatorFn } from '@ngx-formly/core/lib/models';
import { FormlyFieldButtonComponent } from '@shared/components/forms/formly-fields/formly-field-button.component';
import { FormlyFieldDateComponent } from '@shared/components/forms/formly-fields/formly-field-date.component';
import { FormlyFieldDaterangeComponent } from '@shared/components/forms/formly-fields/formly-field-daterange.component';
import { FormlyFieldErrorComponent } from '@shared/components/forms/formly-fields/formly-field-error.component';
import { FormlyFieldFileComponent } from '@shared/components/forms/formly-fields/formly-field-file.component';
import { FormlyFieldInputGroupComponent } from '@shared/components/forms/formly-fields/formly-field-input-group.component';
import { FormlyFieldInputComponent } from '@shared/components/forms/formly-fields/formly-field-input.component';
import { FormlyFieldListComponent } from '@shared/components/forms/formly-fields/formly-field-list/formly-field-list.component';
import { FormlyFieldPasswordComponent } from '@shared/components/forms/formly-fields/formly-field-password.component';
import { FormlyFieldPhoneComponent } from '@shared/components/forms/formly-fields/formly-field-phone.component';
import { FormlyFieldRangeComponent } from '@shared/components/forms/formly-fields/formly-field-range.component';
import { FormlyFieldSelectComponent } from '@shared/components/forms/formly-fields/formly-field-select.component';
import Utils from '@shared/providers/utils';
import { isEmpty, isString } from 'lodash';
import moment from 'moment';

const emptyValidation: FieldValidatorFn = ({ value }: AbstractControl) => {
  const isValueEmpty = isString(value) ? value.trim().length === 0 : isEmpty(value);
  return isValueEmpty ? { empty: true } : null;
};

export const formlyConfig: ConfigOption = {
  extras: { lazyRender: true },
  validationMessages: [
    { name: 'required', message: 'This field is required' },
    {
      name: 'min',
      message: (error: Error, field: FormlyFieldConfig) => `Minimum value is ${field.props?.min}`,
    },
    {
      name: 'max',
      message: (error: Error, field: FormlyFieldConfig) => `Maximum value is ${field.props?.max}`,
    },
    {
      name: 'minLength',
      message: (error: Error, field: FormlyFieldConfig) => `Minimum length is ${field.props?.minLength} characters`,
    },
    {
      name: 'maxLength',
      message: (error: Error, field: FormlyFieldConfig) => `Maximum length is ${field.props?.maxLength} characters`,
    },
    { name: 'empty', message: 'Value is empty' },
    { name: 'date', message: 'Value is not a valid date (YYYY-MM-DD)' },
    { name: 'number', message: 'Value is not a valid number' },
    { name: 'bankNumber', message: 'Value is not a valid bank number (digits only)' },
    { name: 'taxNumber', message: 'Value is not a valid tax number (##-#######) or (9##-##-####)' },
    { name: 'phone', message: 'Value is not a valid US phone number (###-###-####)' },
    { name: 'zip', message: 'Value is not a valid ZIP (#####)' },
    { name: 'email', message: 'Value is not a valid email' },
    { name: 'alreadyUsed', message: 'Value is already in use' },
    { name: 'passwordsNotEqual', message: 'Passwords should match' },
    { name: 'passwordMinLength', message: 'Password has to be at least 8 characters long' },
    { name: 'atLeastOneLowerCase', message: 'Password has to include at least one lower case character' },
    { name: 'atLeastOneUpperCase', message: 'Password has to include at least one upper case character' },
    { name: 'atLeastOneSpecialChar', message: 'Password has to include at least one special (symbol) character' },
    { name: 'atLeastOneNumber', message: 'Password has to include at least one number' },
    { name: 'ncpdp', message: 'Value is not a valid NCPDP (7 digits)' },
    { name: 'npi', message: 'Value is not a valid NPI (10 digits)' },
    { name: 'bin', message: 'Value is not a valid BIN (6 digits or Any)' },
    { name: 'pharmacyWithNpi', message: "Pharmacy with this NPI doesn't exist" },
  ],
  validators: [
    { name: 'empty', validation: emptyValidation },
    {
      name: 'email',
      validation: (c: AbstractControl) => (!c.value || Utils.isValidEmail(c.value) ? null : { email: true }),
    },
    {
      name: 'date',
      validation: (c: AbstractControl) => (!c.value || moment(c.value).isValid() ? null : { date: true }),
    },
    {
      name: 'number',
      validation: (c: AbstractControl) => (!c.value || Utils.isValidNumber(c.value) ? null : { number: true }),
    },
    {
      name: 'bankNumber',
      validation: (c: AbstractControl) => (!c.value || Utils.isValidBankNumber(c.value) ? null : { bankNumber: true }),
    },
    {
      name: 'taxNumber',
      validation: (c: AbstractControl) => (!c.value || Utils.isValidTaxNumber(c.value) ? null : { taxNumber: true }),
    },
    {
      name: 'phone',
      validation: (c: AbstractControl) => (!c.value || Utils.isPhoneNumberValid(c.value) ? null : { phone: true }),
    },
    { name: 'zip', validation: (c: AbstractControl) => (!c.value || Utils.isZipValid(c.value) ? null : { zip: true }) },
    {
      name: 'ncpdp',
      validation: (c: AbstractControl) => (!c.value || Utils.isNcpdpValid(c.value) ? null : { ncpdp: true }),
    },
    {
      name: 'npi',
      validation: (c: AbstractControl) => (!c.value || Utils.isNpiValid(c.value) ? null : { npi: true }),
    },
    {
      name: 'bin',
      validation: (c: AbstractControl) => (!c.value || Utils.isBinValid(c.value) ? null : { bin: true }),
    },
  ],
  types: [
    { name: 'input', component: FormlyFieldInputComponent, wrappers: ['form-field'] },
    { name: 'file', component: FormlyFieldFileComponent, wrappers: ['form-field'] },
    { name: 'button', component: FormlyFieldButtonComponent, wrappers: ['form-field'] },
    { name: 'date', component: FormlyFieldDateComponent, wrappers: ['form-field'] },
    { name: 'dateRange', component: FormlyFieldDaterangeComponent, wrappers: ['form-field'] },
    { name: 'phone', component: FormlyFieldPhoneComponent, wrappers: ['form-field'] },
    { name: 'password', component: FormlyFieldPasswordComponent, wrappers: ['form-field'] },
    { name: 'select', component: FormlyFieldSelectComponent, wrappers: ['form-field'] },
    { name: 'list', component: FormlyFieldListComponent, wrappers: ['form-field'] },
    { name: 'input-group', component: FormlyFieldInputGroupComponent, wrappers: ['form-field'] },
    { name: 'error', component: FormlyFieldErrorComponent, wrappers: ['form-field'] },
    { name: 'range', component: FormlyFieldRangeComponent, wrappers: ['form-field'] },
  ],
};
