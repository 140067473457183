import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ContanctUsForm } from '@app/root/components/contact-us-modal/contact-us-form';
import config from '@config';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { ModalService } from '@services/modal.service';
import { ScriptsService } from '@services/scripts.service';
import { SupportRequestsService } from '@services/support-requests.service';
import { ISupportRequest } from '@shared/models/support-request.model';
import moment from 'moment/moment';

@Component({
  selector: 'app-contact-us-modal',
  templateUrl: './contact-us-modal.component.html',
})
export class ContactUsModalComponent implements OnInit {
  @ViewChild('captchaContainer') public captchaContainer: ElementRef<HTMLDivElement>;
  form: FormGroup = new FormGroup({});
  formFields: FormlyFieldConfig[];
  isSaving: boolean = false;
  isCaptchaVisible: boolean = false;
  captchaValid: boolean = false;
  isSuccessMsg: boolean = false;
  msg?: string;

  constructor(
    private modalService: ModalService,
    private scriptsService: ScriptsService,
    private ngbActiveModal: NgbActiveModal,
    private supportRequestsService: SupportRequestsService,
  ) {}

  ngOnInit() {
    this.formFields = ContanctUsForm.create();
  }

  closeModal() {
    this.ngbActiveModal.close();
  }

  submit() {
    if (!this.captchaValid) {
      this.isCaptchaVisible = true;
      setTimeout(() => {
        this.loadCaptcha();
      });
      return;
    }

    if (this.form.invalid) return;
    this.isSaving = true;
    const requestData: Partial<ISupportRequest> = {
      requester_id: 'login_page',
      help_topic: 'brightscrip.com Contact Form Inquiry',
      requested_time: moment().toISOString(),
      tenant: '',
      ...this.form.value,
    };

    this.supportRequestsService.submitRequest(requestData, false).subscribe({
      next: () => {
        this.isSaving = false;
        this.isSuccessMsg = true;
        this.msg = 'Your request has been successfully sent';
        setTimeout(() => {
          this.closeModal();
        }, 2000);
      },
      error: () => {
        this.isSaving = false;
        this.isSuccessMsg = false;
        this.msg = "Your request hasn't been successfully sent. Please try again later.";
      },
    });
  }

  private loadCaptcha() {
    this.scriptsService.load('aws-captcha', config.captchaUrl).subscribe(() => {
      this.initCaptcha();
    });
  }

  private initCaptcha() {
    AwsWafCaptcha.renderCaptcha(this.captchaContainer.nativeElement, {
      apiKey: config.captchaApiKey,
      disableLanguageSelector: true,
      skipTitle: true,
      onSuccess: () => {
        this.captchaValid = true;
        this.isCaptchaVisible = false;
      },
      onError: () => {
        this.captchaValid = false;
      },
    });
  }
}
