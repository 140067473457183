import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { isNaN } from 'lodash';
import { Observable, Subscription } from 'rxjs';

export type NumberRange = {
  from?: number;
  to?: number;
};

@Component({
  selector: 'app-formly-field-range',
  template: `
    <chitin-range-input
      [formControl]="formControl"
      [ngClass]="{ 'is-invalid': !formControl.valid && formControl.touched && !field.props?.disabled }"
      [name]="field.name"
      [size]="field.props?.size ?? 'medium'"
      [isDisabled]="field.props?.disabled ?? false"
      [readonly]="field.props?.readonly ?? false"
    />
  `,
})
export class FormlyFieldRangeComponent extends FieldType<FieldTypeConfig> implements OnInit, OnDestroy {
  items: any[] = [];
  isLoading = false;
  fieldOptions?: any[] | Observable<any[]>;
  fieldSubscription?: Subscription;
  optionsSubscription?: Subscription;

  @Input()
  inputId?: string;
  @Input()
  name?: string;
  @Input()
  isDisabled: boolean = false;
  @Input()
  readonly: boolean = false;
  @Input()
  size: 'small' | 'medium' | 'large' = 'medium';
  @Input()
  placeholder?: { from: string; to: string };
  @Input()
  value: NumberRange = { from: undefined, to: undefined };
  @Output()
  valueChange = new EventEmitter<NumberRange>();

  ngOnInit() {
    this.setOptions();
    if (this.field.options?.fieldChanges)
      this.fieldSubscription = this.field.options.fieldChanges.subscribe(() => {
        this.setOptions();
      });
  }

  ngOnDestroy() {
    this.fieldSubscription?.unsubscribe();
    this.optionsSubscription?.unsubscribe();
  }

  private setOptions() {
    this.optionsSubscription?.unsubscribe();
    this.fieldOptions = this.field.props?.options;
    if (Array.isArray(this.fieldOptions)) {
      this.items = this.fieldOptions;
    } else if (this.fieldOptions) {
      this.isLoading = true;
      this.optionsSubscription = this.fieldOptions.subscribe(items => {
        this.items = items;
        this.isLoading = false;
      });
    }
  }

  onFromChange(from?: unknown) {
    if (isNaN(from)) return;
    this.value = {
      ...this.value,
      from: from ? (from as number) : undefined,
    };
    this.onValuesChanged();
  }
  onToChange(to?: unknown) {
    if (isNaN(to)) return;
    this.value = {
      ...this.value,
      to: to ? (to as number) : undefined,
    };
    this.onValuesChanged();
  }
  private onValuesChanged() {
    this.valueChange.next(this.value);
    // if (this.onChange) this.onChange(this.value);
  }

  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }

  writeValue(value: NumberRange) {
    this.value = value;
  }
}
