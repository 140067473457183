<table class="table table-sm placeholder-glow" data-cy="userPharmacies">
  <tbody>
    <ng-container *ngIf="items === undefined">
      <tr *ngFor="let _ of [].constructor(field.props?.placeholdersAmount ?? 2)">
        <td></td>
        <td><span class="placeholder col-6">Loading...</span></td>
        <td></td>
      </tr>
    </ng-container>
    <tr *ngIf="items && items.length === 0">
      <td class="text-center">No items found</td>
    </tr>
    <tr
      *ngFor="let item of items; let i = index"
      [ngClass]="{ 'table-danger': item.selectedAction === 'REMOVE', 'table-success': item.selectedAction === 'ADD' }"
    >
      <td class="text-center action-symbol">
        <i class="fa-solid" [ngClass]="{ 'fa-minus': item.selectedAction === 'REMOVE', 'fa-plus': item.selectedAction === 'ADD' }"></i>
      </td>
      <td [ngClass]="{ 'text-decoration-line-through': item.selectedAction === 'REMOVE' }">{{ item.label ?? item.value }}</td>
      <td class="text-center">
        <a (click)="onActionClicked(i)">
          <i
            class="fa-solid"
            [ngClass]="{
              'fa-rotate-left': item.selectedAction === 'REMOVE',
              'fa-circle-xmark': item.selectedAction === 'ADD',
              'fa-trash': item.selectedAction === undefined,
            }"
            data-cy="binBtn"
          ></i>
        </a>
      </td>
    </tr>
  </tbody>
</table>

<div class="row">
  <div class="col-6 ms-auto">
    <div class="mb-2">
      <div class="input-group" data-cy="addNcpdpField">
        <input
          type="text"
          class="form-control"
          placeholder="Add NCPDP"
          [(ngModel)]="newItemValue"
          (ngModelChange)="onNewItemValueChanged()"
        />
        <button class="btn btn-primary" data-cy="addBtn" type="button" (click)="onAddItemClicked()">
          <span *ngIf="!isAdding">Add</span>
          <div *ngIf="isAdding" class="spinner-border spinner-border-sm text-white mx-4">
            <span class="sr-only">Adding...</span>
          </div>
        </button>
        <span *ngIf="itemAddError" class="text-danger ms-auto">{{ itemAddError }}</span>
      </div>
    </div>
  </div>
</div>
