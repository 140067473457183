import { ChangeDetectorRef, Component, inject } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-formly-field-list',
  templateUrl: './formly-field-list.component.html',
  styleUrls: ['./formly-field-list.component.scss'],
})
export class FormlyFieldListComponent extends FieldType {
  private readonly cdr = inject(ChangeDetectorRef);
  newItemValue: string = '';
  isAdding = false;
  itemAddError?: string;

  get items() {
    return this.model[this.key as string];
  }

  onNewItemValueChanged() {
    this.itemAddError = undefined;
  }

  onActionClicked(itemIndex: number) {
    if (itemIndex > this.items.length - 1) return;
    switch (this.items[itemIndex].selectedAction) {
      case 'ADD':
        this.items.splice(itemIndex, 1);
        break;
      case 'REMOVE':
        this.items[itemIndex].selectedAction = undefined;
        break;
      default:
        this.items[itemIndex].selectedAction = 'REMOVE';
        break;
    }
  }

  async onAddItemClicked() {
    this.isAdding = true;
    if (this.field.props?.itemAddedEvent) {
      const validatedValue = await this.field.props.itemAddedEvent(this.newItemValue, this.field, this.model);
      if (validatedValue.item) {
        this.items.push({
          ...validatedValue.item,
          selectedAction: 'ADD',
        });
      } else {
        this.itemAddError = validatedValue.error;
      }
      this.isAdding = false;
      this.newItemValue = '';
    } else {
      // No custom validation
      this.isAdding = false;
      this.items.push({
        value: this.newItemValue,
        selectedAction: 'ADD',
      });
      this.newItemValue = '';
    }
    this.cdr.markForCheck();
  }
}
